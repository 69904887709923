import {html, render} from 'lit-html';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {DOM, PubSub} from '@mamdev/cad.static-next-lib';
import {Config} from '../../ts/services/config';
import Viewport from '../../ts/services/viewport';
import Tracking2 from '../../ts/services/tracking2';

const overlayTemplate = html`
<div class="siteOverlay">
  <div class="overlayWrapper">
    <div class="tr">
      <div class="overlayContent tc">
        <div class="ocw"></div>
      </div>
      <div class="tc sky-overlay-wrapper">
        <div class="co-container ad-overlay-sky js-co-dynamic" data-service-param-tagid="sky_on_overlay"></div>
      </div>
    </div>
  </div>
</div>`;

const contentTemplate = (content, type) => html`
<div class="dialogOverlay" data-type="${type}">
  <div class="dialogWrapper bouncein">
    <div class="close-bar">
      <span class="icon icon-close js-close"></span>
    </div>
    <div class="dialogContent">${unsafeHTML(content)}</div>
  </div>
</div>`;

/**
 * Service to display an overlay element and fill it with the desired markup
 */
class Overlay {
  /**
   * @constructor
   */
  constructor() {
    /**
     * @readonly
     * @type {{dialogContent: string, layerContentWrapper: string, layerContent: string, dialogWrapper: string}}
     */
    this.selectors = {
      layerContent: '.overlayContent',
      layerContentWrapper: '.overlayContent .ocw',
      dialogWrapper: '.dialogWrapper',
      dialogContent: '.dialogContent'
    };
    this.isDialog = false;

    this.avoidVerticalStylingCss = null;
    this.lastScrollPosition = 0;

    /** @type HTMLElement */
    this.overlayElement = null;
  }

  /**
   * Logs stuff
   * @param {*} message
   * @param {*} [data]
   */
  log(message, data) {
    console.log('[overlay]', message, data);
  }

  /**
   * Publish a message to static next
   * @param {string} message
   * @param {*} params
   */
  publish(message, params = {}) {
    PubSub.publish(message, params);
  }

  /**
   * Subscribe to events
   * @param {string} topic
   * @param {Function} callback
   */
  subscribe(topic, callback) {
    PubSub.subscribe(topic, /** @type {(data: any) => void} */(callback));
  }

  /**
   * Init
   */
  init() {
    this.log('init');
    try {
      this.subscribe('article:magnifier', obj => {
        this.log('Magnifier triggered from article', obj);
        obj.overlayInfo = this.createOverlay();

        this.publish('overlay:showBiggerImage', obj);
      });

      this.subscribe('overlayDestroyed', moduleInfo => {
        moduleInfo = moduleInfo || '';
        this.log('overlayDestroyed', moduleInfo);
        try {
          // the overlay element is being deleted from the module that fired this event...
          this.overlayElement = null;
          Viewport.showScrollbar();

          // remove style tag to avoid vertical scroll for android
          if (this.avoidVerticalStylingCss !== null
            || typeof this.avoidVerticalStylingCss !== 'undefined') {
            this.avoidVerticalStylingCss.innerHTML = '';
            // scroll to last position after a magnifier or slideshow
            window.scrollTo(0, this.lastScrollPosition);
          }

          this.publish('ad:refreshAll');
          this.fireTrackingPixel(moduleInfo);
          this.publish('welcomeback:restartWBTimer');
        } catch (e) {
          this.log(e);
          this.publish('sentry:log', {error: e});
        }
      });

      this.subscribe('overlay:showWelcomeback', obj => {
        this.createWelcomebackDialog(obj.content, obj.module, obj.opts, obj.callback);
        this.setDialogSize(obj.module);
      });

      // closes overlay
      this.subscribe('overlay:close', modulename => {
        this.closeDialog(modulename);
      });

      this.subscribe('window:resize', () => {
        if (this.isDialog && this.overlayElement) {
          this.setDialogSize();
        }
      });
    } catch (e) {
      this.log(e);
      this.publish('sentry:log', {error: e});
    }
  }

  /**
   * Creates a dialog overlay.
   *
   * @param {object}  content   The content to place within the dialog overlay
   * @param {string} modulename The name of the module to place within the dialog overlay
   * @param {Object} options Additional params
   * @param {function} callback Callback function called after the dialog has been created
   */
  createWelcomebackDialog(content, modulename, options, callback) {
    callback = callback || function() {};
    options = options || {};

    if (this.overlayElement) {
      this.log('An overlay already exists.');
      return;
    }

    const fragment = DOM.fromHtml('');
    render(contentTemplate(content, modulename), fragment);
    this.overlayElement = /** @type HTMLElement */ (fragment.firstElementChild);

    // CADNPCA-9421
    this.overlayElement.setAttribute('data-open', 'true');
    document.body.appendChild(fragment);

    // TODO: fix for IE not supporting DocumentFragment.firstElementChild;
    this.overlayElement = this.overlayElement || document.querySelector('.dialogOverlay');
    this.log('this.overlayElement', this.overlayElement);

    this.isDialog = true;
    Viewport.hideScrollbar();

    // Options params for tracking variant (CADNPCA-5205)
    const additionalTrackVal = options.wbTrackingVar ? options.wbTrackingVar : '';

    // eventPi
    this.publish('track:welcomebackDialogShow', {
      referrer: '&referrer=undefined',
      click1: '&click1=' + Config.get('pageinfo').coremid,
      click2: '&click2=' + modulename,
      click3: '&click3=open' + additionalTrackVal,
      click4: '&click4=1',
      clickname: '&clickname=' + modulename + '_open'
    });

    // iPad has height 100% issues so, we have to set the height manually
    this.overlayElement.style.height = window.innerHeight + 'px';

    window.setTimeout(() => {
      this.overlayElement.classList.add('fadeIn');
    }, 100);

    const dialogContent = this.overlayElement.getElementsByClassName('dialogContent')[0];

    // insert your module in this method, if you need know that the overlay got created / your module got initialized
    callback(dialogContent);
    this.publish('video:pauseAllPlayers');
    this.publish('welcomeback:stopWBTimer');

    // Handle click events
    this.overlayElement.getElementsByClassName('js-close')[0].addEventListener('click', e => {
      e.preventDefault();
      // this.removeKeyEventHandlers();
      this.closeDialog(modulename);
    });

    // Deregister keyboard events if other overlay is already open
    // if (this.activeOverlays > 1) {
    //   // this.log('Deregistering keyboard event handlers for already opened overlays.');
    //   goog.events.removeAll(document, goog.events.EventType.KEYUP);
    // }

    // Handle keyboard events
    document.addEventListener('keyup', evt => {
      if (evt.key === 'Escape') {
        this.closeDialog(modulename);
      }
    });
  }

  /**
   * Close a dialog overlay.
   * @param {string} modulename The name of the module within the dialog overlay
   */
  closeDialog(modulename) {
    this.log('closeDialog()', modulename);

    try {
      this.isDialog = false;

      // CADNPCA-9421
      this.overlayElement.setAttribute('data-open', 'false');

      // trigger adRefresh after closing dialog
      this.publish('ad:refreshAll');

      this.fireTrackingPixel(modulename);

      if (modulename === 'welcomelayer') {
        Tracking2.callTealiumLink({
          businessEventType: 'userAction',
          componentPath: 'welcomelayer.close-bar.icon-close',
          componentResultState: 'close',
          elementTagName: 'div',
          eventType: 'mousedown'
        });
      }

      this.publish('welcomeback:restartWBTimer');
      this.overlayElement.classList.remove('fadeIn');

      const dialogWrapper = document.querySelector(this.selectors.dialogWrapper);
      dialogWrapper.classList.remove('bouncein');

      // feedback gets its special own animation, after the feedback was successfully sent
      if (modulename === 'feedback_success') {
        dialogWrapper.classList.add('closed');
      } else {
        dialogWrapper.classList.add('bounceout');
      }

      // wait until css transition is finished
      setTimeout(() => {
        this.removeDialog();
      }, 1000);
    } catch (e) {
      this.log(e);
      this.publish('sentry:log', {error: e});
    }
  }

  /**
   * Removes DOM nodes and reset scrolling
   */
  removeDialog() {
    this.overlayElement.remove();
    this.overlayElement = null;
    Viewport.showScrollbar();
  }

  /**
   * Set the dimensions of the dialog overlay.
   * @param {string} [moduleName]
   */
  setDialogSize(moduleName) {
    moduleName = moduleName || this.overlayElement.dataset.type;

    try {
      const contentNode = document.getElementsByClassName('content')[0];

      if (contentNode) {
        let currentWidth = contentNode.getBoundingClientRect().width - 32;

        // Dialog overlay cannot be wider than 900px
        if (currentWidth > 900) {
          currentWidth = 900;
        }

        // larger width for WB-layer (with ad)
        if (moduleName === 'welcomelayer') {
          currentWidth = Math.min(document.documentElement.clientWidth - 32, 1144); // tried out (CADNPCA-4923)
        }

        const closeBar = /** @type HTMLElement */ (this.overlayElement.getElementsByClassName('close-bar')[0]);
        const dialogContent = /** @type HTMLElement */ (this.overlayElement.getElementsByClassName('dialogContent')[0]);
        const innerHeightPx = window.innerHeight + 'px';
        const currentWidthPx = currentWidth + 'px';

        closeBar.style.width = currentWidthPx;
        dialogContent.style.width = currentWidthPx;
        dialogContent.style.maxHeight = innerHeightPx;
        this.overlayElement.style.height = innerHeightPx;
      }
    } catch (e) {
      this.log(e);
      this.publish('sentry:log', {error: e});
    }
  }

  /**
   * Creates the overlay
   * @return {*} overlay info object
   */
  createOverlay() {
    try {
      this.log('createOverlay()');

      if (this.overlayElement) {
        this.log('An overlay already exists.');
        return;
      }

      // get the last scroll position to jump after the overlay destroys at this position
      this.lastScrollPosition = window.scrollY;

      const fragment = DOM.fromHtml('');
      render(overlayTemplate, fragment);
      this.overlayElement = /** @type HTMLElement */ (fragment.firstElementChild);
      document.body.appendChild(fragment);

      // create styling for body and html to avoid vertical scrolling on android
      // check if style tag existing
      const checkIfStyleToAvoidVerticalScrollExisting /** @type HTMLInputElement */
      = (document.getElementById('avoidVerticalScroll'));
      const style = 'html, body {margin: 0; height: 100%; overflow: hidden}';
      if (checkIfStyleToAvoidVerticalScrollExisting) {
        checkIfStyleToAvoidVerticalScrollExisting.innerHTML = style;
      } else {
        this.avoidVerticalStylingCss = document.createElement('style');
        this.avoidVerticalStylingCss.id = 'avoidVerticalScroll';

        this.avoidVerticalStylingCss.innerHTML = style;
        document.getElementsByTagName('head')[0].appendChild(this.avoidVerticalStylingCss);
      }

      // TODO: fix for IE not supporting DocumentFragment.firstElementChild;
      this.overlayElement = this.overlayElement || document.querySelector('.siteOverlay');

      const overlayInfo = {
        overlayElem: this.overlayElement,
        overlayContentSelector: this.selectors.layerContent,
        overlayContentWrapperSelector: this.selectors.layerContentWrapper
      };

      Viewport.hideScrollbar();
      this.refreshAds();

      this.log('overlay created');

      return overlayInfo;
    } catch (e) {
      console.error(e);
      this.publish('sentry:log', {error: e});
    }
  }

  /**
   * Ad refresh on layer
   */
  refreshAds() {
    try {
      this.log('refreshAds()');
      // No ads on overlay in mobile view
      if (Viewport.matches('tablet')) {
        const adInfo = [];
        const dynamicAds = document.querySelectorAll('.js-co-dynamic');

        this.log('dynamicAds', dynamicAds);

        dynamicAds.forEach(adc => {
          this.log('refreshAds: got ad container ', adc);
          // No sky ad for viewport < desktop
          if (!Viewport.matches('desktop') && adc.classList.contains('ad-overlay-sky')) {
            return;
          }

          this.log('refreshAds: pushing ad container', adc);
          adInfo.push(adc);
        });

        this.publish('dynamicAdsNeeded', adInfo);
      }
    } catch (e) {
      this.log(e);
      this.publish('sentry:log', {error: e});
    }
  }

  /**
   * Setup tracking information and fire tracking pixels
   * @param {object|string} moduleInfo contains module name or config object
   */
  fireTrackingPixel(moduleInfo) {
    try {
      let clickname = '';
      let modulename = '';

      // Setup tracking parameters
      if (typeof moduleInfo === 'object') {
        // Reset adservice configuration (e.g. on slideshow close)
        if (moduleInfo.resetAdConfig === true) {
          this.publish('ad:resetAdConfig');
        }

        modulename = moduleInfo.moduleName;
        clickname = modulename + (moduleInfo.optTrackingName ? moduleInfo.optTrackingName : '_close');
      } else {
        modulename = moduleInfo;
        clickname = moduleInfo + '_close';
      }

      if (Config.get('brain')) {
        // slideshow sets coremId as custom parameter because the user could close it after several round trips
        const pageCoremId = moduleInfo.coremId ? moduleInfo.coremId : Config.get('pageinfo').coremid;

        // set clickname 1-4 piParams for tracking
        window.ui.brain.trigger
          = '.' + pageCoremId
            + '.' + modulename
            + '.' + 'close'
            + '.1';
      }

      // normal brainPi
      this.publish('track:dialogCloseNormalPi', {
        referrer: '&referrer=undefined',
        click1: '&click1=undefined',
        click2: '&click2=undefined',
        click3: '&click3=undefined',
        click4: '&click4=undefined',
        clickname: '&clickname=' + clickname
      });
    } catch (e) {
      this.log(e);
      this.publish('sentry:log', {error: e});
    }
  }
}

export default new Overlay();
