/**
 * Dynamically loads Web Components' JS
 * In case a WC's <script> tag was rendered via JS
 * @param {Document | HTMLElement} root The root where the component is contained
 * @param {string} modulesPath Specifies the relative path to js module to be loaded
 */
function wcInit(root: Document | HTMLElement, modulesPath: string) {
  root = root ? root : document;
  const webComponents = root.querySelectorAll('[data-wc-name]');

  webComponents.forEach(wc => {
    const tag = wc.tagName;
    const wcName = wc.getAttribute('data-wc-name');
    if ((customElements.get(tag) === undefined) && !!wcName) {
      const moduleFile = `${modulesPath}/mod-${wcName}/${wcName}.js`;
      try {
        void import(moduleFile);
      } catch (err) {
        console.error('Dynamic loading error: ', err);
      }
    }
  });
}

export {wcInit};
