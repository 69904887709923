/** Try detecting adblocker */
export function tryDetectAdblocker(): boolean {
  let abDetected = false;

  const el = document.createElement('div');
  el.className = 'advertorial adv ad co-container ad-contain';
  el.setAttribute('style', 'width:300px;height:250px;position:absolute;top:-1000px;left:-1000px');
  document.body.appendChild(el);
  const h = el.clientHeight || el.offsetHeight;

  abDetected = (h <= 0);

  return abDetected;
}
